import React from 'react';
import Button from 'components/Button';
import Modal from 'components/Modal';
import { generateText } from 'ai';
import styled from 'styled-components';

const ChatContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 400px;
  padding: 48px 12px 12px 12px;
  margin: 0;
`;

const ChatHistory = styled.div`
  overflow-y: auto;
  flex-grow: 1;
  margin-bottom: 12px;
  padding: 16px;
  border-radius: 12px;
  background: #1a1a1a;
  min-height: 0;

  &::-webkit-scrollbar {
    width: 8px;
  }

  &::-webkit-scrollbar-track {
    background: rgba(255, 255, 255, 0.1);
    border-radius: 4px;
  }

  &::-webkit-scrollbar-thumb {
    background: rgba(255, 255, 255, 0.2);
    border-radius: 4px;
  }
`;

const Message = styled.div`
  margin-bottom: 24px;
  line-height: 1.4;
  color: #ffffff !important;
  font-family:
    -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, sans-serif;

  &.user {
    margin-bottom: 16px;
    color: #ffffff !important;
  }

  &.assistant {
    margin-bottom: 32px;
    color: #ffffff !important;
  }
`;

const InputContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  height: 80px;
  margin-top: auto;
  padding-top: 8px;
`;

const InputWrapper = styled.div`
  position: relative;
  flex-grow: 1;
  height: 60px;
  display: flex;
  align-items: center;
`;

const Input = styled.textarea.attrs({
  'data-testid': 'game-web-search-input',
})`
  flex-grow: 1;
  background: rgba(0, 0, 0, 0.9);
  border: none;
  border-radius: 12px;
  padding: 12px 32px 12px 12px;
  color: #ffffff !important;
  font-size: 14px;
  resize: none;
  height: 100%;
  width: 100%;
  font-family:
    -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, sans-serif;

  &:focus {
    outline: none;
    color: #ffffff !important;
  }

  &::placeholder {
    color: rgba(255, 255, 255, 0.5);
  }
`;

const ClearButton = styled.button`
  position: absolute;
  right: 8px;
  top: 50%;
  transform: translateY(-50%);
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: #808080;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  opacity: 0.7;

  &:hover {
    opacity: 1;
  }

  &::before,
  &::after {
    content: '';
    position: absolute;
    width: 10px;
    height: 2px;
    background: black;
    transform: rotate(45deg);
  }

  &::after {
    transform: rotate(-45deg);
  }
`;

const processMarkdown = (text) => {
  if (!text) return null;
  return text.split(/(\*\*.*?\*\*)/).map((part, index) => {
    const key = `${part.replace(/[^a-zA-Z0-9]/g, '')}-${index}`;
    if (part.startsWith('**') && part.endsWith('**')) {
      const content = part.slice(2, -2);
      return <strong key={key}>{content}</strong>;
    }
    return <span key={key}>{part}</span>;
  });
};

const questionTemplates = [
  (team, opponent, league) =>
    `What was the score the last time ${team} played ${opponent} (${league})`,
];

const GameWebSearch = ({ eventLeagueName, onClose, opponent, show, team }) => {
  const [messages, setMessages] = React.useState([]);
  const [inputText, setInputText] = React.useState('');
  const chatHistoryRef = React.useRef(null);
  const [currentQuestionIndex, setCurrentQuestionIndex] = React.useState(0);

  const generateResponse = React.useCallback(async () => {
    if (!inputText?.trim()) return;

    try {
      const userMessage = {
        id: `user-${Date.now()}`,
        text: inputText,
        type: 'user',
      };
      setMessages((prev) => [...prev, userMessage]);

      const response = await generateText({
        messages: [{ content: inputText, role: 'user' }],
      });

      const aiMessage = {
        id: `ai-${Date.now()}`,
        text: response,
        type: 'assistant',
      };
      setMessages((prev) => [...prev, aiMessage]);

      setInputText('');
      setCurrentQuestionIndex((prev) => prev + 1);
    } catch (error) {
      console.error('Error generating response:', error);
      const errorMessage = {
        id: `error-${Date.now()}`,
        text: 'Sorry, there was an error generating a response.',
        type: 'error',
      };
      setMessages((prev) => [...prev, errorMessage]);
    }
  }, [inputText]);

  const getNextQuestion = React.useCallback(() => {
    if (currentQuestionIndex >= questionTemplates.length) return '';
    return questionTemplates[currentQuestionIndex](
      team,
      opponent,
      eventLeagueName
    );
  }, [currentQuestionIndex, eventLeagueName, opponent, team]);

  React.useEffect(() => {
    setInputText(getNextQuestion());
  }, [getNextQuestion]);

  const scrollToBottom = React.useCallback(() => {
    if (chatHistoryRef.current) {
      chatHistoryRef.current.scrollTop = chatHistoryRef.current.scrollHeight;
    }
  }, []);

  React.useEffect(() => {
    scrollToBottom();
  }, [messages, scrollToBottom]);

  return (
    <Modal onClose={onClose} show={show}>
      <ChatContainer>
        <ChatHistory ref={chatHistoryRef}>
          {messages?.map((message) => (
            <Message className={message.type} key={message.id}>
              {message.text ? processMarkdown(message.text) : null}
            </Message>
          ))}
        </ChatHistory>
        <InputContainer>
          <InputWrapper>
            <Input
              onChange={(e) => setInputText(e.target.value)}
              onKeyDown={(e) => {
                if (e.key === 'Enter' && !e.shiftKey) {
                  e.preventDefault();
                  void generateResponse();
                }
              }}
              placeholder="Ask a question about this game..."
              value={inputText || ''}
            />
            {inputText ? (
              <ClearButton onClick={() => setInputText('')} type="button" />
            ) : null}
          </InputWrapper>
          <Button onClick={() => void generateResponse()} title="Send" />
        </InputContainer>
      </ChatContainer>
    </Modal>
  );
};

export default GameWebSearch;
